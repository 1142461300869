package dev.stralman.pages.posts.om_10_repair

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("posts/om_10_repair/index.md", mapOf("title" to listOf("Analog camera despair: OM-10 needs repair"), "date" to listOf("2022-08-23"), "author" to listOf("Karl Strålman"), "tags" to listOf("analog_cameras")))) {
        dev.stralman.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H2(attrs = { id("backstory") }) {
                org.jetbrains.compose.web.dom.Text("Backstory")
                dev.stralman.components.widgets.navigation.HoverLink("#backstory")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This year a friend of mine got me into analog cameras. I won't go into much detail but it was during a bachelor party, I was under the influence of alcohol and open to vacation activity ideas.. all of which led to me being ever so slightly more susceptible to the thought of having a new hobby.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Now I am not really a \"camera guy\" but I though \"Hey let's give it a shot. It might be fun.\"... I am now an owner of four analog cameras, all of which are from before I was even born. Older cameras tends to not always operate and on a whim they can be inoperatable.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("the-problem") }) {
                org.jetbrains.compose.web.dom.Text("The problem")
                dev.stralman.components.widgets.navigation.HoverLink("#the-problem")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("One of my cameras, the Olympus OM-10, can not trigger its shutter mechanism correctly when pressing the shutter button. When the shutter button is depressed and then released, the film lever is stuck and the film can not advance which also affects the shutter button since it no longer can be depressed (very sad).")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("/images/learn-film-camera-parts.jpg", "Image alt")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Sidenote: Now ignore that I bought this broken camera way before the previously mentioned bachelor party and that it was as inoperatable then as now.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("the-solution") }) {
                org.jetbrains.compose.web.dom.Text("The solution")
                dev.stralman.components.widgets.navigation.HoverLink("#the-solution")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("By opening the bottom cover of the camera and exposing the beautiful mechanical insides, I could trial-and-error my way into triggering the shutter \"retraction\" mechanism by hand. This enabled the film lever to work thus making the shutter button available again! The trigger kick could not reach the retraction lever for the film advance lever resulting in a state where nothing neither the shutter button nor the film advance lever could function without manual intervening (my finger).")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Here's a video in slow motion showing the shutter button trigger mechanism: ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.youtube.com/watch?v=EoTwFuM8sA8", "Link")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("By bending the \"trigger kicker\" inwards I allowed it to reach the retraction lever and the camera functions once again!")
            }
        }
    }
}
