package dev.stralman.pages.posts

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun Mount_with_rclonePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("posts/mount_with_rclone.md", mapOf("title" to listOf("Mount your cloud storage with rclone"), "date" to listOf("2023-01-07"), "author" to listOf("Karl Strålman"), "tags" to listOf("tools")))) {
        dev.stralman.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So I recently came across an awesome tool called rclone. rclone is a command-line tool with many features for managing cloud storage - even so that people refer to it as ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://rclone.org/", "the swiss army knife of cloud storage")
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This post will focus on how to configure remotes and how to mount them. I wrote this mostly as a future reference for myself but also to show a fraction of rclone's capabilities and why others might find it useful.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Configuring a rclone remote is straightforward - basically, just use ")
                dev.stralman.components.widgets.code.InlineCode("""rclone config""")
                org.jetbrains.compose.web.dom.Text(" and follow its interactive setup guide. Enabling it to mount automatically was a bit more involved but not too advanced.")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("#configure-a-rclone-remote", "Configure a rclone remote - dropbox remote example")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("#mount-a-remote-at-every-boot", "Configure remote to mount on boot")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("configure-a-rclone-remote") }) {
                org.jetbrains.compose.web.dom.Text("Configure a rclone remote")
                dev.stralman.components.widgets.navigation.HoverLink("#configure-a-rclone-remote")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Prerequisite:")
                }
                org.jetbrains.compose.web.dom.Text(" ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://rclone.org/install/", "install rclone")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("My dropbox remote configuration prompts differed slightly from what was stated in ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://rclone.org/dropbox/", "the documentation")
                org.jetbrains.compose.web.dom.Text(" which is mainly the reason why I felt like I had to include this section:")
            }
            dev.stralman.components.widgets.code.CodeBlock("""${'$'} rclone config
No remotes found - make a new one
n) New remote
s) Set configuration password
q) Quit config

# 'n' for new remote
n/s/q>n

# What I chose to name my remote
name>dropbox-remote
Type of storage to configure.                          
Enter a string value. Press Enter for the default ("").
Choose a number from below, or type in your own value  
...
 9 / Dropbox    
   \ "dropbox"  
...

# Select '9' for Dropbox remote backend
Storage>9
** See help for dropbox backend at: https://rclone.org/dropbox/ **

Dropbox App Client Id
Leave blank normally.
Enter a string value. Press Enter for the default ("").

# Here I entered blank
client_id>
Dropbox App Client Secret
Leave blank normally.
Enter a string value. Press Enter for the default ("").

# Here I entered blank
client_secret>
Edit advanced config? (y/n)
y) Yes
n) No

# 'n' for no
y/n>n
Remote config
Use auto config?
 * Say Y if not sure
 * Say N if you are working on a remote or headless machine
y) Yes
n) No

# 'y' for auto config
y/n>y
# And then I had to authenticate using a dropbox token - and that was it!
""", lang = "bash")
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Now I was able to list my dropbox directions using ")
                dev.stralman.components.widgets.code.InlineCode("""rclone lsd dropbox-remote:""")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("mount-a-remote-at-every-boot") }) {
                org.jetbrains.compose.web.dom.Text("Mount a remote at every boot")
                dev.stralman.components.widgets.navigation.HoverLink("#mount-a-remote-at-every-boot")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Prerequisite:")
                }
                org.jetbrains.compose.web.dom.Text(" ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://rclone.org/install/", "install rclone")
                org.jetbrains.compose.web.dom.Text(" & configure a remote e.g. ")
                com.varabyte.kobweb.silk.components.navigation.Link("#configure-a-rclone-remote", "dropbox")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("One alternative to mounting a remote automatically is by using a templated user systemd service, ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/rclone/rclone/wiki/Systemd-rclone-mount#systemd", "found here")
                org.jetbrains.compose.web.dom.Text(". The last edit date on this wiki page is October 6 2022 at the time of writing make sure you are using the latest version.")
            }
            org.jetbrains.compose.web.dom.Ol {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Create a service file")
                }
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Save the following content to this file: ")
                    dev.stralman.components.widgets.code.InlineCode("""/etc/systemd/user/rclone@.service""")
                }
            }
            dev.stralman.components.widgets.code.CodeBlock("""[Unit]
Description=RClone mount of users remote %i using filesystem permissions
Documentation=http://rclone.org/docs/
After=network-online.target


[Service]
Type=notify
#Set up environment
Environment=REMOTE_NAME="%i"
Environment=REMOTE_PATH="/"
Environment=MOUNT_DIR="%h/%i"
Environment=POST_MOUNT_SCRIPT=""
Environment=RCLONE_CONF="%h/.config/rclone/rclone.conf"
Environment=RCLONE_TEMP_DIR="/tmp/rclone/%u/%i"
Environment=RCLONE_RC_ON="false"

#Default arguments for rclone mount. Can be overridden in the environment file
Environment=RCLONE_MOUNT_ATTR_TIMEOUT="1s"
#TODO: figure out default for the following parameter
Environment=RCLONE_MOUNT_DAEMON_TIMEOUT="UNKNOWN_DEFAULT"
Environment=RCLONE_MOUNT_DIR_CACHE_TIME="60m"
Environment=RCLONE_MOUNT_DIR_PERMS="0777"
Environment=RCLONE_MOUNT_FILE_PERMS="0666"
Environment=RCLONE_MOUNT_GID="%G"
Environment=RCLONE_MOUNT_MAX_READ_AHEAD="128k"
Environment=RCLONE_MOUNT_POLL_INTERVAL="1m0s"
Environment=RCLONE_MOUNT_UID="%U"
Environment=RCLONE_MOUNT_UMASK="022"
Environment=RCLONE_MOUNT_VFS_CACHE_MAX_AGE="1h0m0s"
Environment=RCLONE_MOUNT_VFS_CACHE_MAX_SIZE="off"
Environment=RCLONE_MOUNT_VFS_CACHE_MODE="off"
Environment=RCLONE_MOUNT_VFS_CACHE_POLL_INTERVAL="1m0s"
Environment=RCLONE_MOUNT_VFS_READ_CHUNK_SIZE="128M"
Environment=RCLONE_MOUNT_VFS_READ_CHUNK_SIZE_LIMIT="off"
#TODO: figure out default for the following parameter
Environment=RCLONE_MOUNT_VOLNAME="UNKNOWN_DEFAULT"

#Overwrite default environment settings with settings from the file if present
EnvironmentFile=-%h/.config/rclone/%i.env

#Check that rclone is installed
ExecStartPre=/usr/bin/test -x /usr/bin/rclone

#Check the mount directory
ExecStartPre=/usr/bin/test -d "${'$'}{MOUNT_DIR}"
ExecStartPre=/usr/bin/test -w "${'$'}{MOUNT_DIR}"
#TODO: Add test for MOUNT_DIR being empty -> ExecStartPre=/usr/bin/test -z "${'$'}(ls -A "${'$'}{MOUNT_DIR}")"

#Check the rclone configuration file
ExecStartPre=/usr/bin/test -f "${'$'}{RCLONE_CONF}"
ExecStartPre=/usr/bin/test -r "${'$'}{RCLONE_CONF}"
#TODO: add test that the remote is configured for the rclone configuration

#Mount rclone fs
ExecStart=/usr/bin/rclone mount \
            --config="${'$'}{RCLONE_CONF}" \
#See additional items for access control below for information about the following 2 flags
#            --allow-other \
#            --default-permissions \
            --rc="${'$'}{RCLONE_RC_ON}" \
            --cache-tmp-upload-path="${'$'}{RCLONE_TEMP_DIR}/upload" \
            --cache-chunk-path="${'$'}{RCLONE_TEMP_DIR}/chunks" \
            --cache-workers=8 \
            --cache-writes \
            --cache-dir="${'$'}{RCLONE_TEMP_DIR}/vfs" \
            --cache-db-path="${'$'}{RCLONE_TEMP_DIR}/db" \
            --no-modtime \
            --drive-use-trash \
            --stats=0 \
            --checkers=16 \
            --bwlimit=40M \
            --cache-info-age=60m \
            --attr-timeout="${'$'}{RCLONE_MOUNT_ATTR_TIMEOUT}" \
#TODO: Include this once a proper default value is determined
#           --daemon-timeout="${'$'}{RCLONE_MOUNT_DAEMON_TIMEOUT}" \
            --dir-cache-time="${'$'}{RCLONE_MOUNT_DIR_CACHE_TIME}" \
            --dir-perms="${'$'}{RCLONE_MOUNT_DIR_PERMS}" \
            --file-perms="${'$'}{RCLONE_MOUNT_FILE_PERMS}" \
            --gid="${'$'}{RCLONE_MOUNT_GID}" \
            --max-read-ahead="${'$'}{RCLONE_MOUNT_MAX_READ_AHEAD}" \
            --poll-interval="${'$'}{RCLONE_MOUNT_POLL_INTERVAL}" \
            --uid="${'$'}{RCLONE_MOUNT_UID}" \
            --umask="${'$'}{RCLONE_MOUNT_UMASK}" \
            --vfs-cache-max-age="${'$'}{RCLONE_MOUNT_VFS_CACHE_MAX_AGE}" \
            --vfs-cache-max-size="${'$'}{RCLONE_MOUNT_VFS_CACHE_MAX_SIZE}" \
            --vfs-cache-mode="${'$'}{RCLONE_MOUNT_VFS_CACHE_MODE}" \
            --vfs-cache-poll-interval="${'$'}{RCLONE_MOUNT_VFS_CACHE_POLL_INTERVAL}" \
            --vfs-read-chunk-size="${'$'}{RCLONE_MOUNT_VFS_READ_CHUNK_SIZE}" \
            --vfs-read-chunk-size-limit="${'$'}{RCLONE_MOUNT_VFS_READ_CHUNK_SIZE_LIMIT}" \
#TODO: Include this once a proper default value is determined
#            --volname="${'$'}{RCLONE_MOUNT_VOLNAME}"
            "${'$'}{REMOTE_NAME}:${'$'}{REMOTE_PATH}" "${'$'}{MOUNT_DIR}"

#Execute Post Mount Script if specified
ExecStartPost=/bin/sh -c "${'$'}{POST_MOUNT_SCRIPT}"

#Unmount rclone fs
ExecStop=/bin/fusermount -u "${'$'}{MOUNT_DIR}"

#Restart info
Restart=always
RestartSec=10

[Install]
WantedBy=default.target

""", lang = "service")
            org.jetbrains.compose.web.dom.Ol {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.P {
                        org.jetbrains.compose.web.dom.Text("Tell systemd to look for new files. ")
                        dev.stralman.components.widgets.code.InlineCode("""systemctl --user daemon-reload""")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.P {
                        org.jetbrains.compose.web.dom.Text("(Optional step) configure a custom mount point. The default mount directory is ")
                        dev.stralman.components.widgets.code.InlineCode("""~/<REMOTE NAME>""")
                        org.jetbrains.compose.web.dom.Text(" if you are ok with that skip this step.")
                    }
                }
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Create an env file: ")
                    dev.stralman.components.widgets.code.InlineCode("""touch ~/.config/rclone/<REMOTE NAME>.env""")
                    org.jetbrains.compose.web.dom.Text(". For me this file is ")
                    dev.stralman.components.widgets.code.InlineCode("""~/.config/rclone/dropbox-remote.env""")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Set the env variable ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("MOUNT_DIR")
                    }
                    org.jetbrains.compose.web.dom.Text(" to whatever mounting directory you want. This directory must exist in the filesystem. For example I want dropbox-remote to moount in /mnt so I set ")
                    dev.stralman.components.widgets.code.InlineCode("""MOUNT_DIR=/mnt/dropbox-remote""")
                    org.jetbrains.compose.web.dom.Text(". Then create that folder e.g. ")
                    dev.stralman.components.widgets.code.InlineCode("""sudo mkdir /mnt/dropbox-remote && sudo chown ${'$'}USER /mnt/dropbox-remote""")
                }
            }
            org.jetbrains.compose.web.dom.Ol {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Start the templated systemd user service for a specific remote.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                dev.stralman.components.widgets.code.InlineCode("""systemctl --user enable rclone@<REMOTE NAME>""")
                org.jetbrains.compose.web.dom.Text(" ")
                dev.stralman.components.widgets.code.InlineCode("""systemctl --user start rclone@<REMOTE NAME>""")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("e.g. for my dropbox this was: ")
                dev.stralman.components.widgets.code.InlineCode("""systemctl --user enable rclone@dropbox-remote""")
                org.jetbrains.compose.web.dom.Text(" ")
                dev.stralman.components.widgets.code.InlineCode("""systemctl --user start rclone@dropbox-remote""")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Now your remote should mount for every reboot.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("checking-out-vfs-cache-modes") }) {
                org.jetbrains.compose.web.dom.Text("Checking out VFS cache modes")
                dev.stralman.components.widgets.navigation.HoverLink("#checking-out-vfs-cache-modes")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Edit: Jan 8, 2022")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The default VFS cache mode ")
                dev.stralman.components.widgets.code.InlineCode("""Environment=RCLONE_MOUNT_VFS_CACHE_MODE="off"""")
                org.jetbrains.compose.web.dom.Text(" or ")
                dev.stralman.components.widgets.code.InlineCode("""--vfs-cache-mode off""")
                org.jetbrains.compose.web.dom.Text(" which it evaluates to prevent my password manager from opening my database file from the remote mount directory for both read and write. I get the following error log from doing this: ")
                dev.stralman.components.widgets.code.InlineCode("""WriteFileHandle: Can't open for write without O_TRUNC""")
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Changing the cache mode from ")
                dev.stralman.components.widgets.code.InlineCode("""--vfs-cache-mode off""")
                org.jetbrains.compose.web.dom.Text(" to ")
                dev.stralman.components.widgets.code.InlineCode("""--vfs-cache-mode writes""")
                org.jetbrains.compose.web.dom.Text(" should support all normal file operations (Source ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://rclone.org/commands/rclone_mount/#file-caching", "here")
                org.jetbrains.compose.web.dom.Text(").")
            }
            org.jetbrains.compose.web.dom.Ol {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Restart service: ")
                    dev.stralman.components.widgets.code.InlineCode("""systemctl --user restart rclone@<REMOTE NAME>""")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Check that it was restarted correctly with correct cache mode: ")
                    dev.stralman.components.widgets.code.InlineCode("""systemctl --user status rclone@<REMOTE NAME>""")
                }
            }
        }
    }
}
