package dev.stralman.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("about.md", mapOf("title" to listOf("About"), "date" to listOf("2022-10-10"), "author" to listOf("Karl Strålman")))) {
        dev.stralman.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hello there!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I'm Kjelle and this blog will cover project write-ups, electronics, interesting dev challenges and other tech related hobbies. It can be anything from super crisp embedded code to reverse engineering and hacking.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Oh I also do ham radio stuff!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("73 de SA6KST")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("/about.jpg", "Image alt")
            }
        }
    }
}
